import React, { Component } from 'react'
import styled, { CSS_VARS, getFont } from 'services/style'

const MainCSS = styled.div`
  position: relative;
  margin-bottom: 30px;
  ${props =>
    !props.normal &&
    `.fg-label {
      vertical-align: middle;
      & + * {
        display: inline-block;
        width: calc(100% - 180px);
        vertical-align: middle;
      }
    }
    @media (max-width: ${CSS_VARS.max_mobile_screen}) {
    .fg-label + * {
      width: calc(100% - 150px);
      }
    }`}
  .fg-fieldError {
    position: absolute;
    top: 100%;
    display: none;
    margin-top: 10px;
    width: 100%;
    color: ${CSS_VARS.color_error};
    ${getFont('RC', 400, 12)};
    text-transform: initial;
  }
  .fg-fieldSuccess {
    position: absolute;
    top: 100%;
    display: none;
    margin-top: 10px;
    width: 100%;
    color: ${CSS_VARS.color_success};
    ${getFont('RC', 400, 12)};
    text-transform: initial;
  }
`

export default class Field extends Component {
  render() {
    const { children } = this.props

    return (
      <MainCSS
        normal={this.props.normal}
        className={`fg-field ${this.props.className ? this.props.className : ''}`}
      >
        {children}
        <div className="fg-fieldError" />
        <div className="fg-fieldSuccess" />
      </MainCSS>
    )
  }
}
