import styled, { createGlobalStyle } from 'styled-components'

export default styled

export const CSS_VARS = {
  // Colors
  color_btn_primary: '#cea264',
  color_btn_secondary: '#004b46',
  color_text: '#212121',
  color_text_light: '#454545',
  color_error: '#d53200',
  color_success: '#00a000',

  // Responsives break points according to Bootstraps
  max_mobile_screen: '575px'
}

export const getFont = (key, weight = 'initial', size = 'initial', uppercase = false) => {
  return `
    font-family: ${{ RC: 'Roboto condensed', OS: 'Open sans' , VR:'Verdana'}[key]};
    font-size: ${size}px;
    font-weight: ${weight};
    text-transform: ${uppercase ? 'capitalize' : 'initial'};
    `
}

/* language=LESS */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    min-height: 100%;
  }
  
  body {
    background-color: #f5f5f5;
    background-size: cover;
    font-family: sans-serif;
  }
  
  * {
    box-sizing: Border-box;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
  }
  
  button,
  fieldset,
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  
  ul {
    list-style-type: none;
    text-transform: initial;
  }
  
  button {
    background-color: transparent;
    border-width: 0;
  }
  
  a:focus, button:focus {
    outline: 1px dotted #fff;
  }
  
  input[disabled], input[readonly] {
    cursor: default;
    opacity: 0.5;
    &:focus {
      outline: none;
    }
  }
  
  /* Show/Hide */
  .fg-show {
    display: block !important;
  }
  
  .fg-hide {
    display: none;
  }
  
   /* Buttons */
  .fg-btn {
    display: inline-block;
    padding: 10px 15px;
    ${getFont('RC', 400, 16)};
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
  }
  
  .fg-btn.isPrimary {
    background-color: ${CSS_VARS.color_btn_primary};
    color: #ffffff;
  }
  
  .fg-btn.isPrimaryBis {
    background-color: ${CSS_VARS.color_btn_primary};
    color: #ffffff;
    border: 2px solid;
    border-radius: 2em;
  }
  
  .fg-btn.isSecondary {
    background-color: #ffffff;
    color: ${CSS_VARS.color_btn_secondary};
    border-color: ${CSS_VARS.color_btn_secondary};
    border: 2px solid;
    border-radius: 2em;
  }

  /* Grise le bouton quand il est désactivé */
  .fg-btn:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    opacity: 0.7;
  }

  /* Form elements */
  .fg-label {
    position: relative;
    display: inline-block;
    width: 150px;
    padding-right: 10px;
    color: #000000;
    ${getFont('OS', 600, 12)};
    line-height: 1.4;
  }
  
  .fg-inputText {
     display: block;
     padding: 10px;
     width: 100%;
     height: 29px;
     border: 1px solid #e6e6e6;
     color: #000000;
     ${getFont('OS', 400, 13)};
     border-radius: 5px;
  }
  
  /* Form */
  .fg-form-columns {
    display: flex;
    justify-content: space-around;
  }
  .fg-form-columns .fg-field {
    max-width: 350px;
    text-align: left;
    border-radius: 10px;
  }
  .fg-form .fg-label-smallText {
    display: block;
    ${getFont('OS', 600, 10)};
  }
  .fg-form-agreement {
    display: flex;
    margin-top: 20px;
    margin-bottom: 0;
    max-width: 100%;
    width: auto;
  }
  .fg-form-agreement .fg-fieldError {
    margin: 15px auto 0;
  }
  .fg-form-agreement .fg-fieldSuccess {
    margin: 15px auto 0;
  }
  .fg-form-agreementLabel {
    margin-left: 10px;
    padding-right: 0;
    width: auto;
    ${getFont('OS', 600, 12)};
    text-align: left;
    vertical-align: middle;
  }
  .fg-form-agreement-small {
    display: block;
    width: auto;
    ${getFont('OS', 600, 10)};
    vertical-align: middle;
  }
  .fg-form-bottom {
    margin-top: 30px;
    text-align: center;
  }
  .fg-form-submit {
    margin-bottom: 20px;
  }
  .fg-form-last {
    color: ${CSS_VARS.color_btn_primary};
    ${getFont('OS', 600, 10)};
  }
  .fg-form-submit {
    margin-bottom: 20px;
    padding: 15px 25px;
  }
  .fg-form-submit-icon {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    fill: #ffffff;
    vertical-align: middle;
  }
  @media (max-width: ${CSS_VARS.max_mobile_screen}) {
    .fg-form-columns {
      display: block;
    }
    .fg-form-agreement {
      position: relative;
      display: flex;
    }
    .fg-form-agreement .fg-fieldError {
      position: absolute;
      bottom: -30px;
      display: block;
      width: 100%;
    }
    .fg-form-agreement .fg-fieldSuccess {
      position: absolute;
      bottom: -30px;
      display: block;
      width: 100%;
    }
    .fg-form-agreementLabel {
      width: auto;
    }
    .fg-form-agreement-small {
      margin-top: 5px;
    }
  }
`
