import React, { useState } from 'react'
import styled, { getFont, CSS_VARS } from 'services/style'
import intl from 'services/intl'
import { ReactComponent as UploadIcon } from 'assets/svg/upload.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/delete.svg'

const MainCSS = styled.div`
  .Upload-label {
    display: block;
    margin-bottom: 5px;
    padding: 0 20px;
    width: 100%;
    height: 36px;
    background-color: ${CSS_VARS.color_btn_primary};
    ${getFont('RC', 400, 12, true)};
    line-height: 36px;
    cursor: pointer;
    border-radius: 2em;
    color : #ffffff;
    text-align: center;
  }
  .Upload-label-icon {
    margin-left: 10px;
    width: 11px;
    height: 11px;
    fill: #ffffff;
  }
  .Upload-fileInfo {
    position: relative;
    display: block;
    padding: 0 30px 0 10px;
    width: 100%;
    height: 36px;
    color: #448233;
    background-color: #f0f0f0;
    ${getFont('OS', 600, 12)};
    font-style: italic;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: lowercase;
    white-space: nowrap;
    border-radius: 10px;
  }
  .Upload-fileInfo-empty {
    color: #c1c1c1;
    font-style: italic;
  }
  .Upload-delete {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .Upload-delete-icon {
    width: 11px;
    height: 11px;
    fill: #000000;
  }
  .Upload-input {
    display: none;
  }
  .Upload-error {
    color: red;
    margin-top: 5px;
    font-size: 12px;
  }
`

const Upload = ({ placeholder, defaultFilename, file: _file, onError, ...props }) => {
  const [id, setId] = useState(`${props.name}-${performance.now()}`)
  const [file, setFile] = useState(_file)
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = event => {
    const file = event.target.files[0]
    const maxSize = 2 * 1024 * 1024

    if (file) {
      if (file.size > maxSize) {
        setErrorMessage(intl`La taille du fichier dépasse la limite autorisée de 2 Mo.`)
        onError && onError(true); // Informe le parent qu'il y a une erreur
        setFile(null)
        return
      }
      if (file.type !== 'application/pdf' && !file.type.startsWith('image/')) {
        setErrorMessage(intl`Le format du fichier n'est pas valide.`)
        onError && onError(true); // Informe le parent qu'il y a une erreur
        setFile(null)
        return
      }
    }

    setErrorMessage('')
    onError && onError(false); // Informe le parent qu'il n'y a pas d'erreur
    const extension = file.type.split('/')[1]
    setFile({
      filename: defaultFilename ? defaultFilename : file.name,
      extension
    })
  }
  const handleDelete = () => {
    setId(`${props.name}-${performance.now()}`)
    setFile(null)
    setErrorMessage('')
    onError && onError(false); // Informe le parent qu'il n'y a pas d'erreur
  }
  return (
    <MainCSS>
      <label className="Upload-label" htmlFor={id}>
        {intl`Choisir un fichier`}
        <UploadIcon className="Upload-label-icon" />
      </label>
      <div className="Upload-fileInfo">
        <span className="Upload-fileInfo-empty">
          {file
            ? file.filename + '.' + file.extension
            : placeholder
            ? placeholder
            : intl`Choisir un fichier`}
        </span>
        {file && (
          <button type="button" className="Upload-delete" onClick={handleDelete}>
            <DeleteIcon className="Upload-delete-icon" />
          </button>
        )}
      </div>
      <input
        {...props}
        key={id}
        className="Upload-input"
        id={id}
        type="file"
        data-required-type={'file'}
        data-required={file ? false : props['data-required']}
        onChange={handleChange}
      />
      {errorMessage && <p className="Upload-error">{errorMessage}</p>}
    </MainCSS>
  )
}

export default Upload
